<template>
  <!-- 打卡主页 -->
  <div id="app" style="background: #f4f4f4">
    <div class="main">
      <div class="cson-container clearfix">
        <div class="content">
          <div class="content-head">
            <div class="content-title">
              刷题打卡
              <div class="content-line"></div>
              <span class="content-subtext">分类刷题，更高效</span>
            </div>
          </div>
          <div class="clearfix">
            <div class="comment-lt">
              <router-link to="/interviewPlan">
                <div class="comment" v-if="mainNotice[0]">
                  <div class="comment-img" style="height: 350px">
                    <img src="@/assets/punch/plan.png" />
                  </div>
                  <div class="comment-mask">
                    <div class="comment-content big-content">
                      <div class="comment-title">
                        {{ mainNotice[0].title }}
                      </div>
                      <div class="comment-question">
                        {{ mainNotice[0].content }}
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="comment-rt">
              <el-row :gutter="10">
                <el-col :span="12">
                  <router-link to="/activity">
                    <div
                      class="comment comment-small"
                      style="height: 170px"
                      v-if="mainNotice[1]"
                    >
                      <div class="comment-img">
                        <img src="@/assets/punch/invite.png" />
                      </div>
                      <div
                        class="comment-mask comment-mask-all comment-mask-samll"
                      >
                        <div class="comment-content">
                          <div class="comment-title">
                            {{ mainNotice[1].title }}
                          </div>
                          <div class="comment-question">
                            {{ mainNotice[1].content }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </el-col>
                <el-col :span="12">
                  <router-link to="">
                    <div
                      class="comment comment-small"
                      style="height: 170px"
                      v-if="mainNotice[2]"
                    >
                      <div class="comment-img">
                        <img src="@/assets/punch/graduate.png" />
                      </div>
                      <div
                        class="comment-mask comment-mask-all comment-mask-samll"
                      >
                        <div class="comment-content">
                          <div class="comment-title">
                            {{ mainNotice[2].title }}
                          </div>
                          <div class="comment-question">
                            {{ mainNotice[2].content }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </el-col>
                <el-col :span="24">
                  <router-link to="/story">
                    <div class="comment" v-if="mainNotice[3]">
                      <div class="comment-img" style="height: 170px">
                        <img src="@/assets/punch/story.png" />
                      </div>
                      <div
                        class="comment-mask comment-mask-all"
                        style="padding-top: 58px"
                      >
                        <div class="comment-content">
                          <div class="comment-title">
                            {{ mainNotice[3].title }}
                          </div>
                          <div class="comment-question">
                            {{ mainNotice[3].content }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </div>
          <publishComment type="default"></publishComment>
        </div>
        <div class="aside">
          <div class="aside-top">
            <button type="button" class="my-dk" @click="myClock">
              我的打卡
            </button>
          </div>

          <div class="aside-panel userInfo" v-if="userInfo && userInfo.id">
            <userInfoPanel></userInfoPanel>
          </div>

          <myGroupPanel v-if="userInfo && userInfo.id"></myGroupPanel>

          <groupPanel></groupPanel>
          <groupTestPanel></groupTestPanel>
          <privateTestPanel></privateTestPanel>
          <div class="aside-course">
            <router-link to=""
              ><img src="../../assets/punch/course1.png"
            /></router-link>
          </div>
          <div class="aside-course">
            <router-link to=""
              ><img src="../../assets/punch/course2.png"
            /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMainNotice } from "../../service/punchClock.js";

import userInfoPanel from "./panels/userInfoPanel";
import myGroupPanel from "./panels/myGroupPanel";
import groupPanel from "./panels/groupPanel";
import groupTestPanel from "./panels/groupTestPanel";
import privateTestPanel from "./panels/privateTestPanel";
import publishComment from "./publishComment";
import { mapState } from "vuex";
export default {
  name: "punchClock",
  data() {
    return {
      current: 0,
      textarea1: "",
      input: "",
      createVisible: false,

      groupComTotal: 0,

      mainNotice: [],

      moreGroupVisible: false,
    };
  },
  components: {
    userInfoPanel,
    myGroupPanel,
    groupPanel,
    groupTestPanel,
    privateTestPanel,
    publishComment,
  },
  methods: {
    getMainNotice() {
      getMainNotice().then((res) => {
        if (res.success) {
          this.mainNotice = res.result;
        }
      });
    },

    myClock() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.$router.push("/punchClockInit");
    },
  },
  computed: mapState(["userInfo"]),
  mounted() {
    this.getMainNotice();
  },
};
</script>

<style lang="css" scoped>
.main {
  padding-bottom: 20px;
}

.content {
  width: 890px;
  float: left;
}

.aside {
  width: 300px;
  float: right;
}

.content-head {
  padding: 20px 0;
}

.content-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #0075f6;
  line-height: 27px;
}

.content-subtext {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}

.content-line {
  width: 2px;
  height: 28px;
  margin: 0 13px;
  background: rgba(136, 148, 171, 0.2);
}

.comment {
  position: relative;
}

.comment-mask {
  overflow: hidden;
  position: absolute;
  width: 100%;
  padding: 15px 40px 15px 20px;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s;
}
.comment-mask:hover {
  background-color: rgba(0, 0, 0, 0.45);
}
.comment-content {
  position: absolute;
  top: 100%;
  transition: 0.3s;
}
.comment-mask:hover .comment-content {
  top: 20px;
  bottom: none;
}
.comment-mask:hover .comment-content.big-content {
  top: 45%;
  bottom: none;
}

.comment-title {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;
  margin-bottom: 10px;
}

.comment-question {
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  margin-bottom: 12px;
}

.comment-foot {
  display: flex;
  align-items: center;
}

.comment-number {
  color: #fff;
  font-size: 12px;
}

.comment-user {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  flex: 1;
}

.comment-name {
  margin-right: 10px;
}

.comment-avatar {
  font-size: 0;
  margin-right: 14px;
}

.comment-avatar img {
  height: 18px;
  width: 18px;
  border-radius: 100%;
}

.comment-lt {
  float: left;
  width: 458px;
}

.comment-rt {
  float: right;
  width: 422px;
}

.comment-img {
  font-size: 0;
}

.comment-mask-all {
  height: 100%;
  padding: 20px 40px 20px 15px;
}

.comment-mask-all .comment-avatar {
  margin-right: 5px;
}

.comment-mask-all .comment-name {
  margin-right: 5px;
}

.comment-mask-all .comment-question {
  font-size: 12px;
  margin-bottom: 20px;
}

.comment-tag {
  font-size: 12px;
  color: #ffcf2d;
  line-height: 18px;
  padding-left: 5px;
  border-left: 1px solid #ffcf2d;
  margin-bottom: 15px;
}

.comment-mask-samll {
  height: 100%;
  padding: 50px 20px 10px 5px;
}

.comment-mask-samll .comment-question {
  margin-bottom: 35px;
}

.comment-small {
  margin-bottom: 10px;
}

.btn-default {
  color: #34495e;
  background-color: rgba(136, 148, 171, 0.4);
}

.btn-warn {
  color: #fff;
  background-color: rgba(250, 100, 0, 1);
}

.team-comment__ava {
  font-size: 0;
}

.team-comment {
  display: flex;
}

.team-comment__ava img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.team-comment__hd {
  width: 24px;
  margin-right: 6px;
}

.team-comment__bd {
  flex: 1;
}

.team-comment__name {
  font-size: 14px;
  font-weight: bold;
  color: #34495e;
  line-height: 20px;
}

.team-comment__jj {
  font-weight: 400;
  color: #898989;
  line-height: 18px;
  font-size: 12px;
  margin-bottom: 5px;
}

.team-comment__desc {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 6px;
}

.team-comment__time {
  font-size: 12px;
  color: #898989;
  line-height: 18px;
  margin-bottom: 8px;
}

.team-comment__number {
  color: rgba(0, 0, 0, 0.85);
  line-height: 18px;
  font-size: 12px;
}

.el-pagination /deep/ .el-pager li {
  height: 21px;
  line-height: 21px;
  min-width: 22px;
  font-size: 12px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.el-pagination.is-background /deep/ .el-pager li:not(.disabled).active {
  background-color: rgba(250, 100, 0, 1);
}

.el-pagination /deep/ button,
.el-pagination /deep/ span {
  font-size: 12px;
  height: 21px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 50px;
}

.el-pagination .last {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.el-pagination .first {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pagination-block {
  text-align: center;
  padding: 25px 0;
}

.my-dk {
  color: #ffffff;
  line-height: 24px;
  font-size: 14px;
  background: #fa6400;
  border-radius: 16px;
  width: 86px;
  text-align: center;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.aside-top {
  text-align: right;
  padding: 20px 0;
  min-height: 68px;
}

.aside-title {
  color: #fa6400;
  line-height: 22px;
  font-size: 14px;
}

.aside-more {
  font-weight: 500;
  color: #8894ab;
  line-height: 22px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.aside-rt {
  float: right;
}
.aside-lt {
  float: left;
}

.aside-panel {
  background-color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.aside-head {
  padding: 24px 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.panel {
  background-color: #fff;
  margin-top: 10px;
  border-radius: 4px;
}

.group-cell {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.group-cells {
  padding: 18px 0;
}

.group-cell__hd {
  width: 42px;
  height: 42px;
  background: #ececec;
  border-radius: 4px;
  border: 1px solid #cecece;
  margin-right: 14px;
}

.group-cell__bd {
  text-align: left;
  flex: 1;
}

.jion-btn {
  color: #fa6400;
  font-size: 12px;
  background: rgba(250, 100, 0, 0.1);
  border-radius: 2px;
  line-height: 20px;
  text-align: center;
  width: 48px;
  border-color: transparent;
}

.group-name {
  color: #34495e;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #34495e;
}

.group-trophy {
  color: #b6daff;
  font-size: 12px;
  margin-left: 15px;
}

.group-data {
  line-height: 18px;
}

.group-data span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 12px;
  margin-right: 10px;
}

.el-table--mini /deep/ td,
.el-table--mini /deep/ th {
  padding: 0;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
}

.el-table--mini /deep/ th {
  background: rgba(0, 117, 246, 0.1);
  color: #34495e;
}

.el-table--mini /deep/ .el-table__row td:first-child .cell div {
  width: 16px;
  height: 16px;
  background: #b6daff;
  line-height: 16px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  margin: 0 auto;
}

.el-table--mini /deep/ .el-table__row:first-child td:first-child .cell div {
  background: #ff5a43;
}

.el-table--mini /deep/ .el-table__row:nth-child(2) td:first-child .cell div {
  background: #44d7b6;
}

.el-table--mini /deep/ .el-table__row:nth-child(3) td:first-child .cell div {
  background: #f8c51c;
}

.el-table--mini /deep/ .el-table__body-wrapper {
  padding: 10px 0;
}

.el-table::before {
  background-color: transparent;
}

.aside-course {
  font-size: 0;
  margin-bottom: 10px;
}

.aside-course img {
  width: 100%;
  height: 130px;
}

.el-dialog__wrapper /deep/ .el-dialog__body {
  padding: 20px 60px 50px;
}

.dialog-title .bread-tabs-head {
  font-size: 18px;
  line-height: 32px;
}

.dialog-head {
  padding: 0 50px 12px;
  border-bottom: 1px solid rgba(235, 236, 241, 0.91);
}

.dialog-title {
  float: left;
}

.dialog-title .bread-tab-line {
  margin: 0 6px;
}

.dialog-search {
  float: right;
}

.icon-up {
  color: #ff5a43 !important;
}

.icon-down1 {
  color: #44d7b6 !important;
}
.myGroup .aside-lt {
  color: #34495e;
  font-size: 14px;
  border-left: 2px solid #fa6400;
  padding-left: 8px;
}
.panel::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}
.panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.panel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.dynamic-form-item .el-button {
  width: 90px;
}

.dynamic-form-item /deep/ .el-textarea__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item /deep/ .el-input__inner {
  background-color: rgba(235, 236, 241, 0.43);
}

.dynamic-form-item + .dynamic-form-item {
  margin-top: 10px;
}

.push-btn {
  background-color: #fa6400;
  border-color: #fa6400;
  color: #fff;
  float: right;
}

.dynamic-form-item .el-select {
  width: 125px;
}

.dynamic-form-item /deep/ .el-select .el-input__inner {
  background-color: #fff;
}

.bg-line {
  margin: 0 -40px;
  height: 20px;
  background: #f8f8f8;
}

.dynamic-form {
  padding: 20px;
  background: #fff;
  margin-top: 10px;
  border-radius: 4px;
}
::v-deep .el-upload.el-upload--picture-card {
  display: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  margin-top: 8px;
}
</style>
